var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0"},[_c('tr',[_c('td',{staticStyle:{"width":"80px !important","padding-right":"4px !important"}},[_c('div',[_c('v-checkbox',{staticClass:"mb-5",attrs:{"primary":"","hide-details":"","value":_vm.item.empleado_id},on:{"click":function($event){return _vm.selectEmployee(_vm.item.empleado_id)}},model:{value:(_vm.selected_general),callback:function ($$v) {_vm.selected_general=$$v},expression:"selected_general"}})],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_c('td',{},[_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.item.contratos[0].contrato_estatus_id != 2)?_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModalPDF(_vm.item.contratos[0])}}},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v("remove_red_eye")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Previsualizar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.item.contratos[0].contrato_estatus_id != 2)?_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.descargar(_vm.item.contratos[0])}}},on),[_c('v-icon',{staticClass:"iconoDelgadoBoton",domProps:{"textContent":_vm._s('$get_app_outline')}})],1):_vm._e()]}}])},[_c('span',[_vm._v("Descargar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:" botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModalCargaDocumento(_vm.item,_vm.item.contratos[0].id)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v("cloud_upload")])],1)]}}])},[_c('span',[_vm._v("Subir contrato firmado")])])],1)]),_c('td',{staticStyle:{"min-width":"70px!important","max-width":"81px!important","width":"80px","padding-left":"8px"}},[_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){_vm.isOpen ? _vm.isOpen = false:_vm.isOpen = true}}},on),[_c('v-icon',{staticClass:"tamIconoBoton iconoDelgadoBoton"},[_vm._v(_vm._s(_vm.isOpen ? 'keyboard_arrow_up':'keyboard_arrow_down'))])],1)]}}])},[_c('span',[_vm._v("Desplegar")])])],1)])]),(_vm.isOpen)?[_vm._m(3),_vm._l((_vm.data),function(contrato){return _c('tr',{key:contrato.id,staticClass:"td-short"},[_c('td',{staticStyle:{"width":"80px !important"}}),_c('td',{staticStyle:{"padding-left":"6px !important"}},[_c('div',[_c('v-list',{staticClass:"py-0 my-0",staticStyle:{"background":"transparent"}},[_c('v-list-item',{staticClass:"py-0 my-0 px-0"},[_c('v-list-item-avatar',{staticClass:"py-0 my-0"},[_c('v-img',{staticClass:"py-0 my-0",attrs:{"src":_vm.urlImg+_vm.item.foto}})],1),_c('v-list-item-content',{staticClass:"ml-0 py-0 my-0"},[_c('v-list-item-title',{staticClass:"tblPrincipal"},[_vm._v(_vm._s(_vm.item.nombre_completo))]),_c('v-list-item-title',{staticClass:"tblSecundario text-mute"},[_vm._v(_vm._s(/* rol=="root"?item.empleado.cliente.nombre:rol=="admin"?item.empleado.empresa.nombre:item.empleado.sucursal.nombre*/))])],1)],1)],1)],1)]),_c('td'),_c('td',[_c('div',[(_vm.item.contratos[0].contrato_estatus_id == 1)?_c('p',{staticClass:"listo mb-0 text-center"},[_vm._v(_vm._s(_vm.item.contratos[0].estatus))]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.item.contratos[0].contrato_estatus_id == 2)?_c('p',_vm._g({staticClass:"incompleto mb-0 text-center"},on),[_vm._v(_vm._s(_vm.item.contratos[0].estatus))]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.item.contratos[0].observaciones))])]),(_vm.item.contratos[0].contrato_estatus_id == 3)?_c('p',{staticClass:"firmado mb-0 text-center"},[_vm._v(_vm._s(_vm.item.contratos[0].estatus))]):_vm._e(),(_vm.item.contratos[0].contrato_estatus_id == 4)?_c('p',{staticClass:"renovar mb-0 text-center"},[_vm._v(_vm._s(_vm.item.contratos[0].estatus))]):_vm._e(),(_vm.item.contratos[0].contrato_estatus_id == 5)?_c('p',{staticClass:"vencido mb-0 text-center"},[_vm._v(_vm._s(_vm.item.contratos[0].estatus))]):_vm._e(),(_vm.item.contratos[0].contrato_estatus_id == 6)?_c('p',{staticClass:"cancelado mb-0 text-center"},[_vm._v(_vm._s(_vm.item.contratos[0].estatus))]):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.item.contratos[0].contrato_estatus_id != 2)?_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModalPDF(contrato)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v("remove_red_eye")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Previsualizar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.item.contratos[0].contrato_estatus_id != 2)?_c('v-btn',_vm._g({staticClass:" botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.descargar(contrato)}}},on),[_c('v-icon',{staticClass:"iconoDelgadoBoton",domProps:{"textContent":_vm._s('$get_app_outline')}})],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Descargar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:" botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModalCargaDocumento(_vm.item,contrato.id)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v("cloud_upload")])],1)]}}],null,true)},[_c('span',[_vm._v("Subir contrato firmado")])])],1)]),_c('td',{staticStyle:{"min-width":"70px!important","max-width":"81px!important","width":"80px","padding-left":"8px"}})])})]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticStyle:{"padding-left":"16px !important"}},[_c('div',[_vm._v(" Q01 / P001 ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('div',[_vm._v(" 01/05/21 ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('div',[_vm._v(" STP ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticStyle:{"background":"#FAFBFF !important"}},[_c('td',{staticStyle:{"width":"80px !important"}}),_c('td',{staticStyle:{"padding-left":"16px !important"}},[_vm._v(" Empleado ")]),_c('td',[_vm._v("Estatus")]),_c('td',{staticStyle:{"min-width":"70px!important","max-width":"81px!important","width":"80px","padding-left":"8px"}})])}]

export { render, staticRenderFns }